import { Link } from "react-router-dom";
import { DataProducts } from "../../../components/particles/data";

const Products = () => {
    return (
        <>
            <div className="my-8">
                <div className="container mx-auto px-6">
                    <h1 className="mt-3 text-[14px] text-black text-center font-normal tracking-[.20rem]">FEATURED COLLECTION</h1>
                    <h2 className="text-center uppercase text-3xl tracking-widest text-[#1c1c1c]">Best seller</h2>
                    <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
                        {
                            DataProducts.map((data, index) => {
                                return (
                                    <Link to={`/products/${data.productTag}`} state={{ data }}>
                                        <div key={index} className="w-full max-w-sm mx-auto">
                                            <div className="flex items-end justify-end w-full bg-cover">
                                                <img src={data.productThumbnail} alt="alt img" className="max-h-[350px] w-full" />
                                            </div>
                                            <div className="px-5 py-3 text-center">
                                                <h3 className="text-gray-700 text-sm uppercase tracking-widest">{data.productName}</h3>
                                                <div className="inline-flex text-center items-center space-x-2 mt-2">
                                                    <h4 className=" text-primary tracking-widest">{data.productPrice}</h4>
                                                    <h5 className=" text-secondary text-sm line-through tracking-widest">{data.productDiscount}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }  
                    </div>
                </div>
            </div>
            <div className="flex justify-center pb-16 pt-16">
                <button className="bg-[#1c1c1c] text-[#ffffff] py-2 px-6 tracking-widest">VIEW PRODUCTS</button>
            </div>
            <hr className="h-px"/>
        </>      
  );
}

export default Products;



