
const Footer = () => {
    return (
        <>
            <div className="md:flex md:justify-between p-[3rem] bg-black text-white">
                <div className="xl:w-1/5 lg:w-[35%] md:w-1/3">
                    <p className="uppercase tracking-wider">e-newsletter</p>
                    <p className="text-gray-500 text-[14px] tracking-wider my-5">Daftar dan jadilah yang pertama mengetahui tentang pendatang baru, promosi, acara di dalam toko, dan banyak lagi.</p>
                    <div className="mt-2 shadow-sm">
                        <input type="text" placeholder="E-mail" className="w-full tracking-widest rounded-md py-1.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-[16px] sm:leading-9"/>
                    </div>
                    <button className="bg-[#1c1c1c] py-2 px-6 mt-5 tracking-widest">SUBSCRIBE</button>
                    <div className="py-5 flex items-center">
                        <div className="container max-w-screen-lg mx-auto">
                            <div>
                                <div className="flex flex-wrap gap-2">
                                    <button className="p-2 font-semibold text-gray-500 inline-flex items-center space-x-2 rounded">
                                    <svg className="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" /></svg>
                                    </button>

                                    <button className="p-2 font-semibold text-gray-500 inline-flex items-center space-x-2 rounded">
                                    <svg className="w-5 h-5 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" /></svg>
                                    </button>

                                    <button className="p-2 font-semibold text-gray-500 inline-flex items-center space-x-2 rounded">
                                    <svg className="w-5 h-5 fill-current" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0zm5.01 4.744c.688 0 1.25.561 1.25 1.249a1.25 1.25 0 0 1-2.498.056l-2.597-.547-.8 3.747c1.824.07 3.48.632 4.674 1.488.308-.309.73-.491 1.207-.491.968 0 1.754.786 1.754 1.754 0 .716-.435 1.333-1.01 1.614a3.111 3.111 0 0 1 .042.52c0 2.694-3.13 4.87-7.004 4.87-3.874 0-7.004-2.176-7.004-4.87 0-.183.015-.366.043-.534A1.748 1.748 0 0 1 4.028 12c0-.968.786-1.754 1.754-1.754.463 0 .898.196 1.207.49 1.207-.883 2.878-1.43 4.744-1.487l.885-4.182a.342.342 0 0 1 .14-.197.35.35 0 0 1 .238-.042l2.906.617a1.214 1.214 0 0 1 1.108-.701zM9.25 12C8.561 12 8 12.562 8 13.25c0 .687.561 1.248 1.25 1.248.687 0 1.248-.561 1.248-1.249 0-.688-.561-1.249-1.249-1.249zm5.5 0c-.687 0-1.248.561-1.248 1.25 0 .687.561 1.248 1.249 1.248.688 0 1.249-.561 1.249-1.249 0-.687-.562-1.249-1.25-1.249zm-5.466 3.99a.327.327 0 0 0-.231.094.33.33 0 0 0 0 .463c.842.842 2.484.913 2.961.913.477 0 2.105-.056 2.961-.913a.361.361 0 0 0 .029-.463.33.33 0 0 0-.464 0c-.547.533-1.684.73-2.512.73-.828 0-1.979-.196-2.512-.73a.326.326 0 0 0-.232-.095z" /></svg>
                                    </button>

                                    <button className="p-2 font-semibold text-gray-500 inline-flex items-center space-x-2 rounded">
                                    <svg className="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" /></svg>
                                    </button> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div></div>
                <div className="xl:w-[30%] lg:w-[35%] md:w-1/3">
                    <p className="uppercase tracking-wider mb-4">THE UNDERWEAR SUPPLY</p>
                    {/* <p className="text-sm tracking-wider text-[14px] my-1 font-semibold">Reputable underwear & loungewear producer</p> */}
                    <p className="text-gray-500 text-[14px] tracking-wider my-1 mb-6">Kenyamanan sejak 1976. Dapatkan pakaian dalam & produk berkualitas tinggi dari produsen terpercaya. Bergabunglah sebagai reseller dan raih keuntungan maksimal!</p>
                
                    {/* <p className="text-sm tracking-wider text-[14px] my-1 font-semibold">Why should you buy our product?</p> */}
                    <p className="text-gray-500 text-[14px] tracking-wider my-1 mb-6">
                        Lebih dari sekadar bisnis, jadilah bagian dari keluarga The Underwear Supply. Dapatkan produk berkualitas dan kembangkan bisnis Anda bersama kami.
                    </p>

                    <p className="text-gray-500 text-[14px] tracking-wider my-1 mb-6">
                        Dukung ekonomi Indonesia dengan membeli produk UMKM dan banggakan buatan dalam negeri! 
                    </p>
                </div>
            </div>

            <div className="block md:hidden px-7 bg-[#b8584b] shadow-2lg sticky bottom-0 text-white">
                <div className="flex">
                    <div className="flex-1 group">
                        <a href="/collections/all-mens" className="flex items-end justify-center text-center mx-auto px-4 pt-2 w-full text-gray-400 group-hover:text-indigo-500">
                            <span className="block px-1 pt-1 pb-1">
                                <span className="block text-xs pb-2">Men</span>
                                <span className="block w-5 mx-auto h-1 group-hover:bg-white rounded-full"></span>
                            </span>
                        </a>
                    </div>
                    <div className="flex-1 group">
                        <a href="/collections/all-women" className="flex items-end justify-center text-center mx-auto px-4 pt-2 w-full text-gray-400 group-hover:text-indigo-500">
                            <span className="block px-1 pt-1 pb-1">
                                <span className="block text-xs pb-2">Women</span>
                                <span className="block w-5 mx-auto h-1 group-hover:bg-white rounded-full"></span>
                            </span>
                        </a>
                    </div>
                    <div className="flex-1 group">
                        <a href="/collections/all-teens" className="flex items-end justify-center text-center mx-auto px-4 pt-2 w-full text-gray-400 group-hover:text-indigo-500">
                            <span className="block px-1 pt-1 pb-1">
                                <span className="block text-xs pb-2">Teens</span>
                                <span className="block w-5 mx-auto h-1 group-hover:bg-white rounded-full"></span>
                            </span>
                        </a>
                    </div>
                    <div className="flex-1 group">
                        <a href="/collections/all-kids" className="flex items-end justify-center text-center mx-auto px-4 pt-2 w-full text-gray-400 group-hover:text-indigo-500">
                            <span className="block px-1 pt-1 pb-1">
                                <span className="block text-xs pb-2">Kids</span>
                                <span className="block w-5 mx-auto h-1 group-hover:bg-white rounded-full"></span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </>
  );
}

export default Footer;
