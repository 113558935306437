export const navbar = [
    {
        id: 0,
        menu: "Our Brand",
        total: 1,
        list: [{
            id: 0,
            category: "OUR BRANDS",
            menuList: [
                {
                    name: "PIERRE UNO",
                    link: ""
                },
                {
                    name: "JORDAN CAVALLI",
                    link: ""
                },
                {
                    name: "BEYONDSKIN",
                    link: ""
                },
                {
                    name: "RUDES & SON",
                    link: ""
                },
                {
                    name: "ARROW APPLE",
                    link: ""
                },
                {
                    name: "CATASY",
                    link: ""
                },
                {
                    name: "PIERRE UNO KIDS",
                    link: ""
                },
                {
                    name: "FINY GIRLS",
                    link: ""
                },
            ]
        }]
    },
    {
        id: 0,
        menu: "MEN",
        total: 4,
        list: [
            {
                id: 0,
                category: "PIERRE UNO",
                menuList: [
                    {
                        name: "PIERRE UNO",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    },
                    {
                        name: "Sleep/Lounge",
                        link: ""
                    },
                ]
            },
            {
                id: 1,
                category: "JORDAN CAVALLI",
                menuList: [
                    {
                        name: "JORDAN CAVALLI",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    }
                ]
            },
            {
                id: 2,
                category: "BEYONDSKIN",
                menuList: [
                    {
                        name: "BEYONDSKIN",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    }
                ]
            },
            {
                id: 3,
                category: "RUDES & SON",
                menuList: [
                    {
                        name: "RUDES & SON",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    },
                    {
                        name: "Sleep/Lounge",
                        link: ""
                    },
                ]
            }
        ]
    },
    {
        id: 0,
        menu: "MEN",
        total: 2,
        list: [
            {
                id: 0,
                category: "ARROW APPLE",
                menuList: [
                    {
                        name: "ARROW APPLE",
                        link: ""
                    },
                    {
                        name: "All Products",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    },
                ]
            },
            {
                id: 1,
                category: "STYL",
                menuList: [
                    {
                        name: "STYL ",
                        link: ""
                    },
                    {
                        name: "All Products ",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Pajamas",
                        link: ""
                    },
                    {
                        name: "Nightgowns",
                        link: ""
                    }
                ]
            }
        ]
    }
]

export const DataProducts = [
    {
        productTag: "Finy Girls",
        productName: "FI-B26 G (CD Bunny (Best Seller)",
        productPrice: "IDR. 53,500",
        productDiscount: "IDR. 89.000",
        productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
        productColor: ["Yellow", "Green"],
        productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
        productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
        productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
        productImageList: [
            "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
            "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0390.jpg",
            "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0393.jpg",
            "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0394.jpg",
            "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0397.jpg",
            "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0399.jpg",
            "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0401.jpg",
        ],
        productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
    }, 
    {
        productName: "PU-B05 B (CD Car (Best Seller)",
        productTag: "Pierre Uno",
        productPrice: "IDR. 53,500",
        productDiscount: "IDR. 89.000",
        productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
        productColor: ["Yellow", "Green"],
        productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
        productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
        productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
        productImageList: [
            "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
            "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0066.jpg",
            "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0074.jpg",
            "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0082.jpg",
            "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0186.jpg",
            "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0191.jpg",
            "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0197.jpg",
        ],
        productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
        productSizeChart: "../img/Size Chart/tus.jpg",
    }, 
    {
        productTag: "Arrow Apple",
        productName: "CD Wanita  RIO ( Renda )",
        productPrice: "IDR. 53,500",
        productDiscount: "IDR. 89.000",
        productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
        productColor: ["Yellow", "Green"],
        productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
        productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
        productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
        productImageList: [
            "../img/women/aa/rio/Rio - Renda/IMG_0041.JPG",
            "../img/women/aa/rio/Rio - Renda/IMG_0053.JPG",
            "../img/women/aa/rio/Rio - Renda/IMG_0189.JPG",
            "../img/women/aa/rio/Rio - Renda/IMG_0198.JPG",
            "../img/women/aa/rio/Rio - Renda/IMG_0203.JPG",
            "../img/women/aa/rio/Rio - Renda/IMG_0206.JPG",
            "../img/women/aa/rio/Rio - Renda/IMG_0209.JPG",
        ],
        productThumbnail: "../img/women/aa/rio/Rio - Renda/IMG_0041.JPG",
        productSizeChart: "../img/Size Chart/SizeChart.png",
    },
    {
        productTag: "Arrow Apple",
        productName: "CD Wanita  RIO ( Renda )",
        productPrice: "IDR. 53,500",
        productDiscount: "IDR. 89.000",
        productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
        productColor: ["Yellow", "Green"],
        productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
        productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
        productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
        productImageList: [
            "../img/women/aa/rio/Rio - Renda/IMG_0041.JPG",
            "../img/women/aa/rio/Rio - Renda/IMG_0053.JPG",
            "../img/women/aa/rio/Rio - Renda/IMG_0189.JPG",
            "../img/women/aa/rio/Rio - Renda/IMG_0198.JPG",
            "../img/women/aa/rio/Rio - Renda/IMG_0203.JPG",
            "../img/women/aa/rio/Rio - Renda/IMG_0206.JPG",
            "../img/women/aa/rio/Rio - Renda/IMG_0209.JPG",
        ],
        productThumbnail: "../img/women/aa/rio/Rio - Renda/IMG_0041.JPG",
        productSizeChart: "../img/Size Chart/SizeChart.png",
    },
]

export const DataProduct = [
    {
        id: 0,
        category: "All",
        slug: "all-mens",
        type: "MENS",
        listProduct: [
            {
                productName: "",
                productTag: "Pierre Uno",
                productPrice: "IDR. 39,500",
                productDiscount: "IDR. 55.500",
                productSize: ["M", "L", "XL"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Celana dalam basic", "Jahitan dan pengerjaan yang rapi", "3 pcs per box multi color", "Karet dibuat dengan teknologi jepang"],
                productFabric: ["Katun 100%"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/JC/JC-BS01M/001.png",
                    "../img/JC/JC-BS01M/002.png",
                    "../img/JC/JC-BS01M/003.png",
                    "../img/JC/JC-BS01M/004.png",
                    "../img/JC/JC-BS01M/005.png",
                    "../img/JC/JC-BS01M/006.png",
                    "../img/JC/JC-BS01M/007.png",
                ],
                productThumbnail: "../img/JC/JC-BS01M/001.png",
                productSizeChart: "../img/Size Chart/Size Chart PU.jpeg",
            },
            {
                productName: "PIERRE UNO 3-PACK CELANA DALAM KATUN BASIC",
                productTag: "Pierre Uno",
                productPrice: "IDR. 39,500",
                productDiscount: "IDR. 55.500",
                productSize: ["M", "L", "XL"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Celana dalam basic", "Jahitan dan pengerjaan yang rapi", "3 pcs per box multi color", "Karet dibuat dengan teknologi jepang"],
                productFabric: ["Katun 100%"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/PU/PU-B01M/001.png",
                    "../img/PU/PU-B01M/002.png",
                    "../img/PU/PU-B01M/003.png",
                    "../img/PU/PU-B01M/004.png",
                    "../img/PU/PU-B01M/005.png",
                    "../img/PU/PU-B01M/006.png",
                    "../img/PU/PU-B01M/007.png",
                    "../img/PU/PU-B01M/008.png",
                ],
                productThumbnail: "../img/PU/PU-B01M/001.png",
                productSizeChart: "../img/Size Chart/Size Chart PU.jpeg",
            },
            {
                productName: "PIERRE UNO EXTRA SIZE UNDERWEAR",
                productTag: "Pierre Uno",
                productPrice: "IDR. 67,500",
                productDiscount: "IDR. 95.500",
                productSize: ["3l", "4L", "5L"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Celana dalam basic", "Jahitan dan pengerjaan yang rapi", "3 pcs per box multi color", "Karet dibuat dengan teknologi jepang"],
                productFabric: ["100% katun"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/PU/PU-B06M/001.png",
                    "../img/PU/PU-B06M/002.png",
                    "../img/PU/PU-B06M/003.png",
                    "../img/PU/PU-B06M/004.png",
                    "../img/PU/PU-B06M/005.png",
                    "../img/PU/PU-B06M/006.png",
                    "../img/PU/PU-B06M/007.png",
                    "../img/PU/PU-B06M/008.png",
                ],
                productThumbnail: "../img/PU/PU-B06M/001.png",
                productSizeChart: "../img/Size Chart/Size Chart PU.jpeg",
            },
            {
                productName: "PIERRE UNO 2-PACK CELANA DALAM BOXER SPORT MICROFIER BX12",
                productTag: "Pierre Uno",
                productPrice: "IDR. 54,500",
                productDiscount: "IDR. 77.500",
                productSize: ["M", "L", "XL"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Menggunakan teknologi seamless (tanpa jahitan pinggir)", "Jahitan dan pengerjaan yang rapi", "Terbuat dari bahan Microfiber", "Halus dan nyaman di kulit",],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/PU/PU-BX12M/001.png",
                    "../img/PU/PU-BX12M/002.png",
                    "../img/PU/PU-BX12M/003.png",
                    "../img/PU/PU-BX12M/004.png",
                    "../img/PU/PU-BX12M/005.png",
                    "../img/PU/PU-BX12M/006.png",
                ],
                productThumbnail: "../img//PU/PU-BX12M/001.png",
                productSizeChart: "../img/Size Chart/Size Chart PU.jpeg",
            },
            {
                productName: "PIERRE UNO 2-PACK CELANA DALAM BOXER SPORT MICROFIER BX13",
                productTag: "Pierre Uno",
                productPrice: "IDR. 54,500",
                productDiscount: "IDR. 77.500",
                productSize: ["M", "L", "XL"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Menggunakan teknologi seamless (tanpa jahitan pinggir)", "Jahitan dan pengerjaan yang rapi", "Terbuat dari bahan Microfiber", "Halus dan nyaman di kulit"],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "/img/PU/PU-BX13M/001.png",
                    "/img/PU/PU-BX13M/002.png",
                    "/img/PU/PU-BX13M/003.png",
                    "/img/PU/PU-BX13M/004.png",
                    "/img/PU/PU-BX13M/005.png",
                    "/img/PU/PU-BX13M/006.png",
                ],
                productThumbnail: "/img/PU/PU-BX13M/001.png",
                productSizeChart: "../img/Size Chart/Size Chart PU.jpeg",
            },
            {
                productName: "PIERRE UNO 2-PACK CELANA DALAM BOXER SPORT MICROFIER BX15",
                productTag: "Pierre Uno",
                productPrice: "IDR. 54,500",
                productDiscount: "IDR. 77.500",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/PU/PU-BX15M/001.JPG",
                    "../img/PU/PU-BX15M/002.JPG",
                    "../img/PU/PU-BX15M/003.JPG",
                    "../img/PU/PU-BX15M/004.JPG",
                    "../img/PU/PU-BX15M/005.JPG",
                    "../img/PU/PU-BX15M/006.JPG",
                    "../img/PU/PU-BX15M/007.JPG",
                ],
                productThumbnail: "../img/PU/PU-BX15M/001.JPG",
                productSizeChart: "../img/Size Chart/Size Chart PU.jpeg",
                productColor: "../img/PU/PU-B01M/color.png",
            },
            {
                productName: "P+1",
                productTag: "P+1",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["M", "L", "XL"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/p+1/P+1-01/001.png",
                    "../img/p+1/P+1-01/002.png",
                    "../img/p+1/P+1-01/003.png",
                    "../img/p+1/P+1-01/004.png",
                    "../img/p+1/P+1-01/005.png",
                    "../img/p+1/P+1-01/006.png",
                ],
                productThumbnail: "../img/p+1/P+1-01/001.png",
                productSizeChart: "../img/Size Chart/Size Chart P+1.jpeg",
            },
            {
                productName: "P+1",
                productTag: "P+1",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["M", "L", "XL"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/p+1/P+1-02/001.png",
                    "../img/p+1/P+1-02/002.png",
                    "../img/p+1/P+1-02/003.png",
                    "../img/p+1/P+1-02/004.png",
                    "../img/p+1/P+1-02/005.png",
                    "../img/p+1/P+1-02/006.png",
                ],
                productThumbnail: "../img/p+1/P+1-02/001.png",
                productSizeChart: "../img/Size Chart/Size Chart P+1.jpeg",
            }
        ]
    },
    {
        id: 1,
        category: "All",
        slug: "all-women",
        type: "WOMEN",
        listProduct: [
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita  RIO ( Renda )",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/rio/Rio - Renda/IMG_0041.JPG",
                    "../img/women/aa/rio/Rio - Renda/IMG_0053.JPG",
                    "../img/women/aa/rio/Rio - Renda/IMG_0189.JPG",
                    "../img/women/aa/rio/Rio - Renda/IMG_0198.JPG",
                    "../img/women/aa/rio/Rio - Renda/IMG_0203.JPG",
                    "../img/women/aa/rio/Rio - Renda/IMG_0206.JPG",
                    "../img/women/aa/rio/Rio - Renda/IMG_0209.JPG",
                ],
                productThumbnail: "../img/women/aa/rio/Rio - Renda/IMG_0041.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita  RIO ( Bis )",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/rio/Rio - Bis/IMG_0041.JPG",
                    "../img/women/aa/rio/Rio - Bis/IMG_0045.JPG",
                    "../img/women/aa/rio/Rio - Bis/IMG_0047.JPG",
                    "../img/women/aa/rio/Rio - Bis/IMG_0051.JPG",
                    "../img/women/aa/rio/Rio - Bis/IMG_0053.JPG",
                ],
                productThumbnail: "../img/women/aa/rio/Rio - Bis/IMG_0045.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.png",
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita  Alfa",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/alfa/IMG_0003.JPG",
                    "../img/women/aa/alfa/IMG_0011.JPG",
                    "../img/women/aa/alfa/IMG_0012.JPG",
                    "../img/women/aa/alfa/IMG_0019.JPG",
                    "../img/women/aa/alfa/IMG_0021.JPG",
                    "../img/women/aa/alfa/IMG_0027.JPG",
                ],
                productThumbnail: "../img/women/aa/alfa/IMG_0003.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita Cotton ( Renda )",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/cotton/IMG_0157.JPG",
                    "../img/women/aa/cotton/IMG_0160.JPG",
                    "../img/women/aa/cotton/IMG_0162.JPG",
                    "../img/women/aa/cotton/IMG_0165.JPG",
                    "../img/women/aa/cotton/IMG_0166.JPG",
                    "../img/women/aa/cotton/IMG_0168.JPG",
                    "../img/women/aa/cotton/IMG_0176.JPG",
                    "../img/women/aa/cotton/IMG_0178.JPG",
                    "../img/women/aa/cotton/IMG_0180.JPG",
                ],
                productThumbnail: "../img/women/aa/cotton/IMG_0157.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita FF",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/ff/IMG_0107.JPG",
                    "../img/women/aa/ff/IMG_0109.JPG",
                    "../img/women/aa/ff/IMG_0111.JPG",
                    "../img/women/aa/ff/IMG_0114.JPG",
                    "../img/women/aa/ff/IMG_0117.JPG",
                    "../img/women/aa/ff/IMG_0121.JPG",
                ],
                productThumbnail: "../img/women/aa/ff/IMG_0107.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita FZ",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/fz/IMG_0083.JPG",
                    "../img/women/aa/fz/IMG_0085.JPG",
                    "../img/women/aa/fz/IMG_0088.JPG",
                    "../img/women/aa/fz/IMG_0094.JPG",
                    "../img/women/aa/fz/IMG_0095.JPG",
                    "../img/women/aa/fz/IMG_0098.JPG",
                ],
                productThumbnail: "../img/women/aa/fz/IMG_0083.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG"
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita FA",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/FA/FA 1.JPG",
                    "../img/women/aa/FA/FA 2.JPG",
                    "../img/women/aa/FA/FA 3.JPG",
                    "../img/women/aa/FA/FA 4.JPG",
                    "../img/women/aa/FA/FA 5.JPG",
                    "../img/women/aa/FA/FA 6.JPG",
                    "../img/women/aa/FA/FA 7.JPG",
                    "../img/women/aa/FA/FA 8.JPG",
                ],
                productThumbnail: "../img/women/aa/FA/FA 1.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG"
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita Prima",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/Prima/IMG_0060.JPG",
                    "../img/women/aa/Prima/IMG_0067.JPG",
                    "../img/women/aa/Prima/IMG_0070.JPG",
                    "../img/women/aa/Prima/IMG_0073.JPG",
                    "../img/women/aa/Prima/IMG_0075.JPG",
                    "../img/women/aa/Prima/IMG_0077.JPG",
                ],
                productThumbnail: "../img/women/aa/Prima/IMG_0060.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG"
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Hamil",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/CD Hamil/IMG_0007.JPG",
                    "../img/women/aa/CD Hamil/IMG_0009.JPG",
                    "../img/women/aa/CD Hamil/IMG_0012.JPG",
                    "../img/women/aa/CD Hamil/IMG_0097.JPG",
                    "../img/women/aa/CD Hamil/IMG_0100.JPG",
                    "../img/women/aa/CD Hamil/IMG_0105.JPG",
                    "../img/women/aa/CD Hamil/IMG_0114.JPG",
                    "../img/women/aa/CD Hamil/IMG_0117.JPG",
                ],
                productThumbnail: "../img/women/aa/CD Hamil/IMG_0007.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG"
            },
        ]
    },
    {
        id: 0,
        category: "All",
        slug: "all-teens",
        type: "TEENS",
        listProduct: [
            {
                productTag: "Arrow Apple",
                productName: "Arrow Apple Teens",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/teens/AA Teens/AA/1.JPG",
                    "../img/teens/AA Teens/AA/2.JPG",
                    "../img/teens/AA Teens/AA/3.JPG",
                    "../img/teens/AA Teens/AA/4.JPG",
                    "../img/teens/AA Teens/AA/5.JPG",
                    "../img/teens/AA Teens/AA/6.JPG",
                    "../img/teens/AA Teens/AA/7.JPG",
                ],
                productThumbnail: "../img/teens/AA Teens/AA/1.JPG"
            },
            {
                productTag: "Jordan Cavalli",
                productName: "Jordaan Cavalli Teens",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/teens/JC Teens/JC-BR01T/1.JPG",
                    "../img/teens/JC Teens/JC-BR01T/2.JPG",
                    "../img/teens/JC Teens/JC-BR01T/3.JPG",
                    "../img/teens/JC Teens/JC-BR01T/4.JPG",
                    "../img/teens/JC Teens/JC-BR01T/5.JPG",
                ],
                productThumbnail: "../img/teens/JC Teens/JC-BR01T/1.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            }, 
            {
                productTag: "Jordan Cavalli",
                productName: "Jordaan Cavalli Teens",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/teens/JC Teens/JC-BR02T/1.JPG",
                    "../img/teens/JC Teens/JC-BR02T/2.JPG",
                    "../img/teens/JC Teens/JC-BR02T/3.JPG",
                    "../img/teens/JC Teens/JC-BR02T/4.JPG",
                    "../img/teens/JC Teens/JC-BR02T/5.JPG",
                    "../img/teens/JC Teens/JC-BR02T/5.JPG",
                ],
                productThumbnail: "../img/teens/JC Teens/JC-BR02T/1.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            }, 
            {
                productTag: "Pierre Uno",
                productName: "Pierre Uno Teens",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/teens/PU Teens/PU-BS01T/001.png",
                    "../img/teens/PU Teens/PU-BS01T/002.png",
                    "../img/teens/PU Teens/PU-BS01T/003.png",
                    "../img/teens/PU Teens/PU-BS01T/004.png",
                ],
                productThumbnail: "../img/teens/PU Teens/PU-BS01T/001.png",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            }, 
            {
                productTag: "Pierre Uno",
                productName: "Pierre Uno Teens",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/teens/PU Teens/PU-BR02T/001.png",
                    "../img/teens/PU Teens/PU-BR02T/002.png",
                    "../img/teens/PU Teens/PU-BR02T/003.png",
                    "../img/teens/PU Teens/PU-BR02T/004.png",
                ],
                productThumbnail: "../img/teens/PU Teens/PU-BR02T/001.png",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            },
            {
                productTag: "Pierre Uno",
                productName: "Pierre Uno Teens",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/teens/PU Teens/PU-BS09T/001.png",
                    "../img/teens/PU Teens/PU-BS09T/002.png",
                    "../img/teens/PU Teens/PU-BS09T/003.png",
                    "../img/teens/PU Teens/PU-BS09T/004.png",
                ],
                productThumbnail: "../img/teens/PU Teens/PU-BS09T/001.png",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            },
        ]
    },
    {
        id: 3,
        category: "All",
        slug: "all-kids",
        type: "KIDS",
        listProduct: [
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / NECI",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0035.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0044.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0279.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0281.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0283.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0286.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0288.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0294.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0035.jpg",
            },  
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / KARTUN HEWAN",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0096.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0098.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0333.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0355.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0358.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0360.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0361.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0363.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0369.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.jpg",
            },    
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / CD DREAM",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0338.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0428.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0430.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0431.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0435.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0436.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0435.jpg",
            },  
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / CD Kimono",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0064.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0260.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0262.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0264.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0270.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0272.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0064.jpg",
            },   
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / GIRL ONLY",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0102.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0113.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0344.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0347.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0349.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0352.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0353.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0102.jpg",
            }, 
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / CD LEAP BEFORE SLEEP",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0133.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0137.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0404.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0406.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0408.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0413.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0414.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0133.jpg",
            },  
            {
                productTag: "Finy Girls",
                productName: "FI-B18 G (Little Girl)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0077.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0085.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0086.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0371.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0373.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0375.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0378.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0381.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0077.jpg",
            },
            {
                productTag: "Finy Girls",
                productName: "FI-B20 G (CD Animal Face 01)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0047.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0299.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0303.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0312.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0047.jpg",
            },
            {
                productTag: "Finy Girls",
                productName: "FI-B21 G (CD Femmiephant)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0003.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0009.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0016.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0313.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0317.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0318.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0325.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0003.jpg",
            }, 
            {
                productTag: "Finy Girls",
                productName: "FI-B26 G (CD Bunny (Best Seller)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0390.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0393.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0394.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0397.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0399.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0401.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
            }, 
            {
                productTag: "Finy Girls",
                productName: "FI-BP11 G (CD Bow Fuschia)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0070.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0336.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0417.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0418.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0423.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0425.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0426.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0070.jpg",
            },   
            {
                productTag: "Finy Girls",
                productName: "FI-BR25 G (CD Animal Face 02)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0020.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0249.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0251.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0254.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0258.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0020.jpg",
            },
            {
                productTag: "Finy Girls",
                productName: "FI-S01 G (Girl Singlet)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0131.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0144.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0262.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0265.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0269.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0272.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0274.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0280.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0286.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.jpg",
            },
            {
                productName: "PIERRE UNO 3 PCS CELANA DALAM ANAK LAKI-LAKI / CD Dino",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0146.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0152.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0202.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0204.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0207.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0209.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.jpg",
            }, 
            {
                productName: "PU-B04 B (CD Safari)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0176.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0177.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0178.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0180.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0184.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0187.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0190.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0192.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.jpg",
            }, 
            {
                productName: "PU-B05 B (CD Car (Best Seller)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0066.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0074.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0082.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0186.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0191.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0197.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
            }, 
            {
                productName: "PU-B06 B (CD Polos)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0122.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0125.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0127.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0133.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0254.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0255.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0261.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0262.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.jpg",
            },
            {
                productName: "PU-B08 B (CD Alien)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0094.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0098.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0101.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0106.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0243.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0244.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0252.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0253.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.png",
            }, 
            {
                productName: "PU-B10 B (CD Builder)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0211.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0216.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0221.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0223.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0227.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0229.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0231.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0234.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.png",
            }, 
            {
                productName: "PU-B13 B (CD Pirates)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0008.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0013.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0017.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0018.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0023.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0231.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0235.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0237.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0241.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.png",
            }, 
            {
                productName: "PU-BR12 B (CD Team Cat)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0142.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0149.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0155.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0159.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0169.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0201.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0205.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0211.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0214.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.png",
            }, 
            {
                productName: "PU-BR14 B (CD Space Craft)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0034.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0036.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0045.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0049.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0218.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0219.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0225.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0226.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0228.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.png",
            },
            {
                productName: "PU-KIDS 1X1 +WM",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_02.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_03.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_04.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_05.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_06.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_07.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL.png",
            },
            {
                productName: "PU-S01 B (Boy_s Singlet)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0190.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0193.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0195.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0221.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0224.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0240.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0243.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0245.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0265.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0277.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0282.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0190.png",
            },  
        ]
    },
    {
        id: 4,
        category: "Jordan Cavalli",
        slug: "jordan-cavalli",
        type: "MENS",
        listProduct: [
            {
                productName: "JORDAN CAVALLI 3-PACK CELANA DALAM KATUN BASIC",
                productTag: "JORDAN CAVALLI",
                productPrice: "IDR. 39,500",
                productDiscount: "IDR. 55.500",
                productSize: ["M", "L", "XL"],
                productDescription: ["Celana dalam basic", "Jahitan dan pengerjaan yang rapi", "3 pcs per box multi color", "Karet dibuat dengan teknologi jepang"],
                productFabric: ["Katun 100%"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/JC/JC-BS01M/001.png",
                    "../img/JC/JC-BS01M/002.png",
                    "../img/JC/JC-BS01M/003.png",
                    "../img/JC/JC-BS01M/004.png",
                    "../img/JC/JC-BS01M/005.png",
                    "../img/JC/JC-BS01M/006.png",
                    "../img/JC/JC-BS01M/007.png",
                ],
                productThumbnail: "../img/JC/JC-BS01M/001.png",
                productSizeChart: "../img/Size Chart/Size Chart JC.jpg",
                productColor: "../img/JC/JC-BS01M/color.png",
            },
        ]
    },
    {
        id: 5,
        category: "Pierre Uno",
        slug: "pierre-uno",
        type: "MENS",
        listProduct: [
            {
                productName: "PIERRE UNO 3-PACK CELANA DALAM KATUN BASIC",
                productTag: "Pierre Uno",
                productPrice: "IDR. 39,500",
                productDiscount: "IDR. 55.500",
                productSize: ["M", "L", "XL"],
                productDescription: ["Celana dalam basic", "Jahitan dan pengerjaan yang rapi", "3 pcs per box multi color", "Karet dibuat dengan teknologi jepang"],
                productFabric: ["Katun 100%"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/PU/PU-B01M/001.png",
                    "../img/PU/PU-B01M/002.png",
                    "../img/PU/PU-B01M/003.png",
                    "../img/PU/PU-B01M/004.png",
                    "../img/PU/PU-B01M/005.png",
                    "../img/PU/PU-B01M/006.png",
                    "../img/PU/PU-B01M/007.png",
                    "../img/PU/PU-B01M/008.png",
                ],
                productThumbnail: "../img/PU/PU-B01M/001.png",
                productSizeChart: "../img/Size Chart/Size Chart PU.jpeg",
                productColor: "../img/PU/PU-B01M/color.png",
            },
            {
                productName: "PIERRE UNO EXTRA SIZE UNDERWEAR",
                productTag: "Pierre Uno",
                productPrice: "IDR. 67,500",
                productDiscount: "IDR. 95.500",
                productSize: ["3l", "4L", "5L"],
                productDescription: ["Celana dalam basic", "Jahitan dan pengerjaan yang rapi", "3 pcs per box multi color", "Karet dibuat dengan teknologi jepang"],
                productFabric: ["100% katun"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/PU/PU-B06M/001.png",
                    "../img/PU/PU-B06M/002.png",
                    "../img/PU/PU-B06M/003.png",
                    "../img/PU/PU-B06M/004.png",
                    "../img/PU/PU-B06M/005.png",
                    "../img/PU/PU-B06M/006.png",
                    "../img/PU/PU-B06M/007.png",
                    "../img/PU/PU-B06M/008.png",
                ],
                productThumbnail: "../img/PU/PU-B06M/001.png",
                productSizeChart: "../img/Size Chart/Size Chart PU.jpeg",
                productColor: "../img/PU/PU-B06M/color.png",
            },
            {
                productName: "PIERRE UNO 2-PACK CELANA DALAM BOXER SPORT MICROFIER BX12",
                productTag: "Pierre Uno",
                productPrice: "IDR. 54,500",
                productDiscount: "IDR. 77.500",
                productSize: ["M", "L", "XL"],
                productDescription: ["Menggunakan teknologi seamless (tanpa jahitan pinggir)", "Jahitan dan pengerjaan yang rapi", "Terbuat dari bahan Microfiber", "Halus dan nyaman di kulit",],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/PU/PU-BX12M/001.png",
                    "../img/PU/PU-BX12M/002.png",
                    "../img/PU/PU-BX12M/003.png",
                    "../img/PU/PU-BX12M/004.png",
                    "../img/PU/PU-BX12M/005.png",
                    "../img/PU/PU-BX12M/006.png",
                ],
                productThumbnail: "../img//PU/PU-BX12M/001.png",
                productSizeChart: "../img/Size Chart/Size Chart PU.jpeg",
                productColor: "../img/PU/PU-BX12M/color.png",
            },
            {
                productName: "PIERRE UNO 2-PACK CELANA DALAM BOXER SPORT MICROFIER BX13",
                productTag: "Pierre Uno",
                productPrice: "IDR. 54,500",
                productDiscount: "IDR. 77.500",
                productSize: ["M", "L", "XL"],
                productDescription: ["Menggunakan teknologi seamless (tanpa jahitan pinggir)", "Jahitan dan pengerjaan yang rapi", "Terbuat dari bahan Microfiber", "Halus dan nyaman di kulit"],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "/img/PU/PU-BX13M/001.png",
                    "/img/PU/PU-BX13M/002.png",
                    "/img/PU/PU-BX13M/003.png",
                    "/img/PU/PU-BX13M/004.png",
                    "/img/PU/PU-BX13M/005.png",
                    "/img/PU/PU-BX13M/006.png",
                ],
                productThumbnail: "/img/PU/PU-BX13M/001.png",
                productSizeChart: "../img/Size Chart/Size Chart PU.jpeg",
                productColor: "../img/PU/PU-BX13M/color.png",
            },
            {
                productName: "PIERRE UNO 2-PACK CELANA DALAM BOXER SPORT MICROFIER BX15",
                productTag: "Pierre Uno",
                productPrice: "IDR. 54,500",
                productDiscount: "IDR. 77.500",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/PU/PU-BX15M/001.JPG",
                    "../img/PU/PU-BX15M/002.JPG",
                    "../img/PU/PU-BX15M/003.JPG",
                    "../img/PU/PU-BX15M/004.JPG",
                    "../img/PU/PU-BX15M/005.JPG",
                    "../img/PU/PU-BX15M/006.JPG",
                    "../img/PU/PU-BX15M/007.JPG",
                ],
                productThumbnail: "../img/PU/PU-BX15M/001.JPG",
                productSizeChart: "../img/Size Chart/Size Chart PU.jpeg",
                productColor: "../img/PU/PU-B01M/color.png",
            },
        ]
    },
    {
        id: 6,
        category: "P+1",
        slug: "p+1",
        type: "MENS",
        listProduct: [
            {
                productName: "P+1",
                productTag: "P+1",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["M", "L", "XL"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/p+1/P+1-01/001.png",
                    "../img/p+1/P+1-01/002.png",
                    "../img/p+1/P+1-01/003.png",
                    "../img/p+1/P+1-01/004.png",
                    "../img/p+1/P+1-01/005.png",
                    "../img/p+1/P+1-01/006.png",
                ],
                productThumbnail: "../img/p+1/P+1-01/001.png",
                productSizeChart: "../img/Size Chart/Size Chart P+1.jpeg",
            },
            {
                productName: "P+1",
                productTag: "P+1",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["M", "L", "XL"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering.", "Untuk warna tua harap dicuci berbeda"], 
                productImageList: [
                    "../img/p+1/P+1-02/001.png",
                    "../img/p+1/P+1-02/002.png",
                    "../img/p+1/P+1-02/003.png",
                    "../img/p+1/P+1-02/004.png",
                    "../img/p+1/P+1-02/005.png",
                    "../img/p+1/P+1-02/006.png",
                ],
                productThumbnail: "../img/p+1/P+1-02/001.png",
                productSizeChart: "../img/Size Chart/Size Chart P+1.jpeg",
            }
        ]
    },
    {
        id: 7,
        category: "Arrow Apple",
        slug: "arrow-apple",
        type: "WOMEN",
        listProduct: [
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita  RIO ( Renda )",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/rio/Rio - Renda/IMG_0041.JPG",
                    "../img/women/aa/rio/Rio - Renda/IMG_0053.JPG",
                    "../img/women/aa/rio/Rio - Renda/IMG_0189.JPG",
                    "../img/women/aa/rio/Rio - Renda/IMG_0198.JPG",
                    "../img/women/aa/rio/Rio - Renda/IMG_0203.JPG",
                    "../img/women/aa/rio/Rio - Renda/IMG_0206.JPG",
                    "../img/women/aa/rio/Rio - Renda/IMG_0209.JPG",
                ],
                productThumbnail: "../img/women/aa/rio/Rio - Renda/IMG_0041.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita  RIO ( Bis )",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/rio/Rio - Bis/IMG_0041.JPG",
                    "../img/women/aa/rio/Rio - Bis/IMG_0045.JPG",
                    "../img/women/aa/rio/Rio - Bis/IMG_0047.JPG",
                    "../img/women/aa/rio/Rio - Bis/IMG_0051.JPG",
                    "../img/women/aa/rio/Rio - Bis/IMG_0053.JPG",
                ],
                productThumbnail: "../img/women/aa/rio/Rio - Bis/IMG_0045.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.png",
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita  Alfa",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/alfa/IMG_0003.JPG",
                    "../img/women/aa/alfa/IMG_0011.JPG",
                    "../img/women/aa/alfa/IMG_0012.JPG",
                    "../img/women/aa/alfa/IMG_0019.JPG",
                    "../img/women/aa/alfa/IMG_0021.JPG",
                    "../img/women/aa/alfa/IMG_0027.JPG",
                ],
                productThumbnail: "../img/women/aa/alfa/IMG_0003.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita Cotton ( Renda )",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/cotton/IMG_0157.JPG",
                    "../img/women/aa/cotton/IMG_0160.JPG",
                    "../img/women/aa/cotton/IMG_0162.JPG",
                    "../img/women/aa/cotton/IMG_0165.JPG",
                    "../img/women/aa/cotton/IMG_0166.JPG",
                    "../img/women/aa/cotton/IMG_0168.JPG",
                    "../img/women/aa/cotton/IMG_0176.JPG",
                    "../img/women/aa/cotton/IMG_0178.JPG",
                    "../img/women/aa/cotton/IMG_0180.JPG",
                ],
                productThumbnail: "../img/women/aa/cotton/IMG_0157.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita FF",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/ff/IMG_0107.JPG",
                    "../img/women/aa/ff/IMG_0109.JPG",
                    "../img/women/aa/ff/IMG_0111.JPG",
                    "../img/women/aa/ff/IMG_0114.JPG",
                    "../img/women/aa/ff/IMG_0117.JPG",
                    "../img/women/aa/ff/IMG_0121.JPG",
                ],
                productThumbnail: "../img/women/aa/ff/IMG_0107.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita FZ",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/fz/IMG_0083.JPG",
                    "../img/women/aa/fz/IMG_0085.JPG",
                    "../img/women/aa/fz/IMG_0088.JPG",
                    "../img/women/aa/fz/IMG_0094.JPG",
                    "../img/women/aa/fz/IMG_0095.JPG",
                    "../img/women/aa/fz/IMG_0098.JPG",
                ],
                productThumbnail: "../img/women/aa/fz/IMG_0083.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG"
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita FA",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/FA/FA 1.JPG",
                    "../img/women/aa/FA/FA 2.JPG",
                    "../img/women/aa/FA/FA 3.JPG",
                    "../img/women/aa/FA/FA 4.JPG",
                    "../img/women/aa/FA/FA 5.JPG",
                    "../img/women/aa/FA/FA 6.JPG",
                    "../img/women/aa/FA/FA 7.JPG",
                    "../img/women/aa/FA/FA 8.JPG",
                ],
                productThumbnail: "../img/women/aa/FA/FA 1.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG"
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Wanita Prima",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/Prima/IMG_0060.JPG",
                    "../img/women/aa/Prima/IMG_0067.JPG",
                    "../img/women/aa/Prima/IMG_0070.JPG",
                    "../img/women/aa/Prima/IMG_0073.JPG",
                    "../img/women/aa/Prima/IMG_0075.JPG",
                    "../img/women/aa/Prima/IMG_0077.JPG",
                ],
                productThumbnail: "../img/women/aa/Prima/IMG_0060.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG"
            },
            {
                productTag: "Arrow Apple",
                productName: "CD Hamil",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/women/aa/CD Hamil/IMG_0007.JPG",
                    "../img/women/aa/CD Hamil/IMG_0009.JPG",
                    "../img/women/aa/CD Hamil/IMG_0012.JPG",
                    "../img/women/aa/CD Hamil/IMG_0097.JPG",
                    "../img/women/aa/CD Hamil/IMG_0100.JPG",
                    "../img/women/aa/CD Hamil/IMG_0105.JPG",
                    "../img/women/aa/CD Hamil/IMG_0114.JPG",
                    "../img/women/aa/CD Hamil/IMG_0117.JPG",
                ],
                productThumbnail: "../img/women/aa/CD Hamil/IMG_0007.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG"
            },
        ]
    },
    {
        id: 8,
        category: "Arrow Apple",
        slug: "teens-arrow-apple",
        type: "TEENS",
        listProduct: [
            {
                productTag: "Arrow Apple",
                productName: "Arrow Apple Teens",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/teens/AA Teens/AA/1.JPG",
                    "../img/teens/AA Teens/AA/2.JPG",
                    "../img/teens/AA Teens/AA/3.JPG",
                    "../img/teens/AA Teens/AA/4.JPG",
                    "../img/teens/AA Teens/AA/5.JPG",
                    "../img/teens/AA Teens/AA/6.JPG",
                    "../img/teens/AA Teens/AA/7.JPG",
                ],
                productThumbnail: "../img/teens/AA Teens/AA/1.JPG"
            },
        ]
    },
    {
        id: 9,
        category: "Jordan Cavalli Teens",
        slug: "teens-jordan-cavalli",
        type: "TEENS",
        listProduct: [
            {
                productTag: "Jordan Cavalli",
                productName: "Jordaan Cavalli Teens",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/teens/JC Teens/JC-BR01T/1.JPG",
                    "../img/teens/JC Teens/JC-BR01T/2.JPG",
                    "../img/teens/JC Teens/JC-BR01T/3.JPG",
                    "../img/teens/JC Teens/JC-BR01T/4.JPG",
                    "../img/teens/JC Teens/JC-BR01T/5.JPG",
                ],
                productThumbnail: "../img/teens/JC Teens/JC-BR01T/1.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            }, 
            {
                productTag: "Jordan Cavalli",
                productName: "Jordaan Cavalli Teens",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/teens/JC Teens/JC-BR02T/1.JPG",
                    "../img/teens/JC Teens/JC-BR02T/2.JPG",
                    "../img/teens/JC Teens/JC-BR02T/3.JPG",
                    "../img/teens/JC Teens/JC-BR02T/4.JPG",
                    "../img/teens/JC Teens/JC-BR02T/5.JPG",
                    "../img/teens/JC Teens/JC-BR02T/5.JPG",
                ],
                productThumbnail: "../img/teens/JC Teens/JC-BR02T/1.JPG",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            }, 
        ]
    },
    {
        id: 10,
        category: "Pierre Uno Teens",
        slug: "teens-pierre-uno",
        type: "TEENS",
        listProduct: [
            {
                productTag: "Pierre Uno",
                productName: "Pierre Uno Teens",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/teens/PU Teens/PU-BS01T/001.png",
                    "../img/teens/PU Teens/PU-BS01T/002.png",
                    "../img/teens/PU Teens/PU-BS01T/003.png",
                    "../img/teens/PU Teens/PU-BS01T/004.png",
                ],
                productThumbnail: "../img/teens/PU Teens/PU-BS01T/001.png",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            }, 
            {
                productTag: "Pierre Uno",
                productName: "Pierre Uno Teens",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/teens/PU Teens/PU-BR02T/001.png",
                    "../img/teens/PU Teens/PU-BR02T/002.png",
                    "../img/teens/PU Teens/PU-BR02T/003.png",
                    "../img/teens/PU Teens/PU-BR02T/004.png",
                ],
                productThumbnail: "../img/teens/PU Teens/PU-BR02T/001.png",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            },
            {
                productTag: "Pierre Uno",
                productName: "Pierre Uno Teens",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/teens/PU Teens/PU-BS09T/001.png",
                    "../img/teens/PU Teens/PU-BS09T/002.png",
                    "../img/teens/PU Teens/PU-BS09T/003.png",
                    "../img/teens/PU Teens/PU-BS09T/004.png",
                ],
                productThumbnail: "../img/teens/PU Teens/PU-BS09T/001.png",
                productSizeChart: "../img/Size Chart/SizeChart.PNG",
            },
        ]
    },
    {
        id: 11,
        category: "Pierre Uno Kid's",
        slug: "kids-pierre-uno",
        type: "KIDS",
        listProduct: [
            {
                productName: "Boy Briefs - Dino",
                productTag: "Pierre Uno",
                productPrice: "IDR. 99,000",
                productDiscount: "",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0146.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0152.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0202.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0204.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0207.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0209.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.jpg",
                productSizeChart: "../img/Size Chart/tus.jpg",
                productColor: "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/color.png",
            }, 
            {
                productName: "Boy Briefs - Jungle",
                productTag: "Pierre Uno",
                productPrice: "IDR. 99,000",
                productDiscount: "",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0176.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0177.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0178.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0180.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0184.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0187.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0190.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0192.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.jpg",
                productSizeChart: "../img/Size Chart/tus.jpg",
                productColor: "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/color.png",
            }, 
            {
                productName: "Boy Briefs - Racing",
                productTag: "Pierre Uno",
                productPrice: "IDR. 99,000",
                productDiscount: "",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0066.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0074.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0082.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0186.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0191.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0197.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
                productSizeChart: "../img/Size Chart/tus.jpg",
                productColor: "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/color.png",
            }, 
            {
                productName: "Boy Briefs - Polos",
                productTag: "Pierre Uno",
                productPrice: "IDR. 99,000",
                productDiscount: "",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0122.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0125.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0127.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0133.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0254.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0255.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0261.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0262.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.jpg",
                productSizeChart: "../img/Size Chart/tus.jpg",
                productColor: "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/color.png",
            },
            {
                productName: "Boy Briefs - Space Alien",
                productTag: "Pierre Uno",
                productPrice: "IDR. 99,000",
                productDiscount: "",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0094.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0106.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0243.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0244.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0252.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0253.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.png",
                productSizeChart: "../img/Size Chart/tus.jpg",
                productColor: "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/color.png",
            }, 
            {
                productName: "Boy Briefs - Builder",
                productTag: "Pierre Uno",
                productPrice: "IDR. 99,000",
                productDiscount: "",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0211.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0216.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0221.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0223.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0227.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0229.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0231.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0234.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.png",
                productSizeChart: "../img/Size Chart/tus.jpg",
                productColor: "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/color.png",
            }, 
            {
                productName: "Boy Briefs - Pirate",
                productTag: "Pierre Uno",
                productPrice: "IDR. 99,000",
                productDiscount: "",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0013.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0017.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0018.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0023.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0231.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0235.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0237.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0241.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0013.png",
                productSizeChart: "../img/Size Chart/tus.jpg",
                productColor: "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/color.png",
            }, 
            {
                productName: "Boy Briefs - Team Cat",
                productTag: "Pierre Uno",
                productPrice: "IDR. 99,000",
                productDiscount: "",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0142.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0149.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0155.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0159.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0169.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0201.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0205.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0211.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0214.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.png",
                productSizeChart: "../img/Size Chart/tus.jpg",
                productColor: "../img/PU/PU-B01M/color.png",
            }, 
            {
                productName: "Boy Briefs - Space Craft",
                productTag: "Pierre Uno",
                productPrice: "IDR. 99,000",
                productDiscount: "",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0036.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0045.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0049.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0218.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0219.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0225.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0226.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0228.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0036.png",
                productColor: "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/color.png",
                productSizeChart: "../img/Size Chart/tus.jpg",
            },
            {
                productName: "PU-KIDS 1X1 +WM",
                productTag: "Pierre Uno",
                productPrice: "IDR. 99,000",
                productDiscount: "",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_02.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_03.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_04.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_05.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_06.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_07.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL.png",
                productSizeChart: "../img/Size Chart/tus.jpg",
                productColor: "../img/PU/PU-B01M/color.png",
            },
            {
                productName: "PU-S01 B (Boy_s Singlet)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 99,000",
                productDiscount: "",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0187.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0190.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0193.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0195.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0224.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0240.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0187.png",
                productSizeChart: "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/size.png",
                productColor: "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/color.png",
            },      
        ]
    },
    {
        id: 12,
        category: "Finy Girls Kid's",
        slug: "kids-finy-girls",
        type: "KIDS",
        listProduct: [
            {
                productTag: "Finy Girls",
                productName: "Girl Briefs - Neci",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0035.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0044.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0279.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0281.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0283.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0286.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0288.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0294.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0035.jpg",
                productSizeChart: "../img/Size Chart/sizeChart.png",
                productColor: "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/color.png",
            },  
            {
                productTag: "Finy Girls",
                productName: "Girl Briefs - Animal Head",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0096.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0098.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0333.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0355.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0358.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0360.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0361.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0363.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0369.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.jpg",
                productSizeChart: "../img/Size Chart/sizeChart.png",
                productColor: "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/color.png",
            },  
            {
                productTag: "Finy Girls",
                productName: "Girl Briefs - Dream",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0338.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0428.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0430.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0431.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0435.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0436.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0435.jpg",
                productSizeChart: "../img/Size Chart/sizeChart.png",
                productColor: "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/color.png",
            },  
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / CD Kimono",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0064.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0260.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0262.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0264.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0270.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0272.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0064.jpg",
                productSizeChart: "../img/Size Chart/tus.jpg",
                productColor: "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/color.png",
            },   
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / GIRL ONLY",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0102.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0113.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0344.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0347.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0349.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0352.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0353.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0102.jpg",
                productSizeChart: "../img/Size Chart/sizeChart.png",
                productColor: "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/color.png",
            }, 
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / CD LEAP BEFORE SLEEP",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0133.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0137.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0404.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0406.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0408.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0413.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0414.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0133.jpg",
                productSizeChart: "../img/Size Chart/sizeChart.png",
                productColor: "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/color.png",
            },  
            {
                productTag: "Finy Girls",
                productName: "FI-B18 G (Little Girl)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0077.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0085.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0086.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0371.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0373.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0375.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0378.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0381.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0077.jpg",
                productSizeChart: "../img/Size Chart/sizeChart.png",
                productColor: "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/color.png",
            },
            {
                productTag: "Finy Girls",
                productName: "FI-B20 G (CD Animal Face 01)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0047.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0299.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0303.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0312.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0047.jpg",
                productSizeChart: "../img/Size Chart/sizeChart.png",
                productColor: "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/color.png",
            },
            {
                productTag: "Finy Girls",
                productName: "FI-B21 G (CD Femmiephant)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0003.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0009.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0016.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0313.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0317.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0318.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0325.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0003.jpg",
                productSizeChart: "../img/Size Chart/sizeChart.png",
                productColor: "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/color.png",
            }, 
            {
                productTag: "Finy Girls",
                productName: "FI-B26 G (CD Bunny (Best Seller)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0390.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0393.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0394.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0397.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0399.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0401.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
                productSizeChart: "../img/Size Chart/sizeChart.png",
                productColor: "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/color.png",
            }, 
            {
                productTag: "Finy Girls",
                productName: "FI-BP11 G (CD Bow Fuschia)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0070.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0336.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0417.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0418.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0423.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0425.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0426.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0070.jpg",
                productSizeChart: "../img/Size Chart/sizeChart.png",
                productColor: "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/color.png",
            },
            {
                productTag: "Finy Girls",
                productName: "FI-BR25 G (CD Animal Face 02)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0020.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0249.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0251.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0254.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0258.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0020.jpg",
                productSizeChart: "../img/Size Chart/sizeChart.png",
                productColor: "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/color.png",
            },
            {
                productTag: "Finy Girls",
                productName: "FI-S01 G (Girl Singlet)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0131.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0144.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0262.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0269.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0272.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0274.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0280.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.jpg",
                productSizeChart: "../img/Size Chart/sizeChart.png",
                productColor: "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/color.png",
            },
        ]
    },
]

export const listCategory = [
    {
        productCategory: "MENS",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-mens"
            },
            {
                productBrand: "PIERRE UNO",
                link: "/collections/pierre-uno"
            },
            {
                productBrand: "Jordan Cavalli",
                link: "/collections/jordan-cavalli"
            },
            {
                productBrand: "P+1",
                link: "/collections/p+1"
            }
        ]
    },
    {
        productCategory: "WOMEN",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-women"
            },
            {
                productBrand: "Arrow Apple",
                link: "/collections/arrow-apple"
            }
        ]
    },
    {
        productCategory: "TEENS",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-teens"
            },
            {
                productBrand: "Arrow Apple",
                link: "/collections/arrow-apple"
            },
            {   productBrand: "Arrow Apple", 
                link: "/collections/teens-arrow-apple" 
            },
            {   productBrand: "Jordan Cavalli", 
                link: "/collections/teens-jordan-cavalli" 
            },
            {   productBrand: "Pierre Uno", 
                link: "/collections/teens-pierre-uno" 
            }
        ]
    },
    {
        productCategory: "KIDS",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-kids"
            },
            { productBrand: "Finy Girls", link: "/collections/kids-finy-girls" },
            { productBrand: "Pierre Uno", link: "/collections/kids-pierre-uno" },
        ]
    },
]

export const newJson = [
    {
        category: "MENS",
        productList: [
            {
                category: "all",
                slug: "all",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.png",
                            "/img/jc_boy/2.png",
                            "/img/jc_boy/3.png",
                            "/img/jc_boy/4.png"
                        ],
                        productThumbnail: "/img/jc_boy/1.png"
                    },
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.png",
                            "/img/pu/pu-boxer1/bx2.png",
                            "/img/pu/pu-boxer1/bx3.png",
                            "/img/pu/pu-boxer1/bx4.png",
                            "/img/pu/pu-boxer1/bx5.png",
                            "/img/pu/pu-boxer1/bx6.png",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/PU/PU-BOXER2/bx1.png",
                            "../img/PU/PU-BOXER2/bx2.png",
                            "../img/PU/PU-BOXER2/bx3.png",
                            "../img/PU/PU-BOXER2/bx4.png",
                            "../img/PU/PU-BOXER2/bx5.png",
                            "../img/PU/PU-BOXER2/bx6.png",
                        ],
                        productThumbnail: "../img/PU/PU-BOXER2/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.png",
                            "../img/pu/pu-b01m/img_0026.png",
                            "../img/pu/pu-b01m/img_0030.png",
                            "../img/pu/pu-b01m/img_0032.png",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img//PU/PU-BM05M/img_0089.png",
                            "../img//PU/PU-BM05M/img_0092.png",
                            "../img//PU/PU-BM05M/img_0096.png",
                            "../img//PU/PU-BM05M/img_0097.png",
                            "../img//PU/PU-BM05M/img_0098.png",
                            "../img//PU/PU-BM05M/img_0099.png",
                        ],
                        productThumbnail: "../img//PU/PU-BM05M/img_0089.png"
                    },
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/PO-BS01 m_001.png",
                            "../img/p+1/PO-BS01 m_002.png",
                            "../img/p+1/PO-BS01 m_003.png",
                            "../img/p+1/PO-BS01 m_006.png",
                            "../img/p+1/PO-BS01 M_007.png",
                            "../img/p+1/PO-BS01 m_008.png",
                        ],
                        productThumbnail: "../img/p+1/PO-BS01 m_001.png"
                    }
                ]
            },
            {
                category: "Jordan Cavalli",
                slug: "jordan-cavalli",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.png",
                            "/img/jc_boy/2.png",
                            "/img/jc_boy/3.png",
                            "/img/jc_boy/4.png"
                        ],
                        productThumbnail: "/img/jc_boy/1.png"
                    },
                ]
            },
            {
                category: "Pierre Uno",
                slug: "pierre-uno",
                listProduct: [
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.png",
                            "/img/pu/pu-boxer1/bx2.png",
                            "/img/pu/pu-boxer1/bx3.png",
                            "/img/pu/pu-boxer1/bx4.png",
                            "/img/pu/pu-boxer1/bx5.png",
                            "/img/pu/pu-boxer1/bx6.png",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/PU/PU-BOXER2/bx1.png",
                            "../img/PU/PU-BOXER2/bx2.png",
                            "../img/PU/PU-BOXER2/bx3.png",
                            "../img/PU/PU-BOXER2/bx4.png",
                            "../img/PU/PU-BOXER2/bx5.png",
                            "../img/PU/PU-BOXER2/bx6.png",
                        ],
                        productThumbnail: "../img/PU/PU-BOXER2/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.png",
                            "../img/pu/pu-b01m/img_0026.png",
                            "../img/pu/pu-b01m/img_0030.png",
                            "../img/pu/pu-b01m/img_0032.png",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img//PU/PU-BM05M/img_0089.png",
                            "../img//PU/PU-BM05M/img_0092.png",
                            "../img//PU/PU-BM05M/img_0096.png",
                            "../img//PU/PU-BM05M/img_0097.png",
                            "../img//PU/PU-BM05M/img_0098.png",
                            "../img//PU/PU-BM05M/img_0099.png",
                        ],
                        productThumbnail: "../img//PU/PU-BM05M/img_0089.png"
                    },
                ]
            },
            {
                category: "P+1",
                slug: "p+1",
                listProduct: [
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/PO-BS01 m_001.png",
                            "../img/p+1/PO-BS01 m_002.png",
                            "../img/p+1/PO-BS01 m_003.png",
                            "../img/p+1/PO-BS01 m_006.png",
                            "../img/p+1/PO-BS01 M_007.png",
                            "../img/p+1/PO-BS01 m_008.png",
                        ],
                        productThumbnail: "../img/p+1/PO-BS01 m_001.png"
                    }
                ]
            },
        ]
    },
    {
        category: "WOMEN",
        productList: [
            {
                category: "all",
                slug: "all",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.png",
                            "/img/jc_boy/2.png",
                            "/img/jc_boy/3.png",
                            "/img/jc_boy/4.png"
                        ],
                        productThumbnail: "/img/jc_boy/1.png"
                    },
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.png",
                            "/img/pu/pu-boxer1/bx2.png",
                            "/img/pu/pu-boxer1/bx3.png",
                            "/img/pu/pu-boxer1/bx4.png",
                            "/img/pu/pu-boxer1/bx5.png",
                            "/img/pu/pu-boxer1/bx6.png",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/PU/PU-BOXER2/bx1.png",
                            "../img/PU/PU-BOXER2/bx2.png",
                            "../img/PU/PU-BOXER2/bx3.png",
                            "../img/PU/PU-BOXER2/bx4.png",
                            "../img/PU/PU-BOXER2/bx5.png",
                            "../img/PU/PU-BOXER2/bx6.png",
                        ],
                        productThumbnail: "../img/PU/PU-BOXER2/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.png",
                            "../img/pu/pu-b01m/img_0026.png",
                            "../img/pu/pu-b01m/img_0030.png",
                            "../img/pu/pu-b01m/img_0032.png",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img//PU/PU-BM05M/img_0089.png",
                            "../img//PU/PU-BM05M/img_0092.png",
                            "../img//PU/PU-BM05M/img_0096.png",
                            "../img//PU/PU-BM05M/img_0097.png",
                            "../img//PU/PU-BM05M/img_0098.png",
                            "../img//PU/PU-BM05M/img_0099.png",
                        ],
                        productThumbnail: "../img//PU/PU-BM05M/img_0089.png"
                    },
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/PO-BS01 m_001.png",
                            "../img/p+1/PO-BS01 m_002.png",
                            "../img/p+1/PO-BS01 m_003.png",
                            "../img/p+1/PO-BS01 m_006.png",
                            "../img/p+1/PO-BS01 M_007.png",
                            "../img/p+1/PO-BS01 m_008.png",
                        ],
                        productThumbnail: "../img/p+1/PO-BS01 m_001.png"
                    }
                ]
            },
            {
                category: "Jordan Cavalli",
                slug: "jordan-cavalli",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.png",
                            "/img/jc_boy/2.png",
                            "/img/jc_boy/3.png",
                            "/img/jc_boy/4.png"
                        ],
                        productThumbnail: "/img/jc_boy/1.png"
                    },
                ]
            },
            {
                category: "Pierre Uno",
                slug: "pierre-uno",
                listProduct: [
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.png",
                            "/img/pu/pu-boxer1/bx2.png",
                            "/img/pu/pu-boxer1/bx3.png",
                            "/img/pu/pu-boxer1/bx4.png",
                            "/img/pu/pu-boxer1/bx5.png",
                            "/img/pu/pu-boxer1/bx6.png",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/PU/PU-BOXER2/bx1.png",
                            "../img/PU/PU-BOXER2/bx2.png",
                            "../img/PU/PU-BOXER2/bx3.png",
                            "../img/PU/PU-BOXER2/bx4.png",
                            "../img/PU/PU-BOXER2/bx5.png",
                            "../img/PU/PU-BOXER2/bx6.png",
                        ],
                        productThumbnail: "../img/PU/PU-BOXER2/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.png",
                            "../img/pu/pu-b01m/img_0026.png",
                            "../img/pu/pu-b01m/img_0030.png",
                            "../img/pu/pu-b01m/img_0032.png",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img//PU/PU-BM05M/img_0089.png",
                            "../img//PU/PU-BM05M/img_0092.png",
                            "../img//PU/PU-BM05M/img_0096.png",
                            "../img//PU/PU-BM05M/img_0097.png",
                            "../img//PU/PU-BM05M/img_0098.png",
                            "../img//PU/PU-BM05M/img_0099.png",
                        ],
                        productThumbnail: "../img//PU/PU-BM05M/img_0089.png"
                    },
                ]
            },
            {
                category: "P+1",
                slug: "p+1",
                listProduct: [
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/PO-BS01 m_001.png",
                            "../img/p+1/PO-BS01 m_002.png",
                            "../img/p+1/PO-BS01 m_003.png",
                            "../img/p+1/PO-BS01 m_006.png",
                            "../img/p+1/PO-BS01 M_007.png",
                            "../img/p+1/PO-BS01 m_008.png",
                        ],
                        productThumbnail: "../img/p+1/PO-BS01 m_001.png"
                    }
                ]
            },
        ]
    }
]