import { useLocation } from "react-router-dom";
// import Promo from "../../../components/organs/promo";
import { useState } from "react";
import Badge from "../../../components/molecules/badge";
import Accordion from "../../../components/molecules/accordion";
import { TbRulerMeasure } from "react-icons/tb";

function Details() {
    const state = useLocation();
    const [displayImage, setDisplayImage] = useState(state.state.data.productImageList[0]);
    const [showModal, setShowModal] = useState(false);

  return (
    <div>
        {/* <Promo/> */}
        <section className="py-12 sm:py-16"> 
            <div className="container mx-auto px-4">              
                <div className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                    <div className="lg:col-span-3 lg:row-end-1">
                        <div className="lg:flex lg:items-start">
                        <div className="lg:order-2 lg:ml-5">
                            <div className="max-w-xl overflow-hidden rounded-lg">
                            <img className="h-full w-full max-w-full object-cover" src={displayImage} alt="thumbnail" />
                            </div>
                        </div>

                        <div className="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                            <div className="flex flex-row items-start lg:flex-col">
                                {
                                    state.state.data.productImageList.map((data, index) => {
                                        return (
                                            <button key={index} onClick={() => setDisplayImage(data)} type="button" className="flex-0 aspect-square mb-3 h-20 overflow-hidden text-center rounded-lg">
                                                <img className="w-full mx-1" src={data} alt="" />
                                            </button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2 tracking-[0.2rem]">
                        <p className="text-inactive text-sm">{state.state.data.productTag}</p>

                        <h3 className="my-2 font-normal text-gray-800 text-xl md:text-xl">{state.state.data.productName}</h3>
                        <div className="flex items-center space-x-4 my-4">
                            <h4 className=" text-primary tracking-widest">{state.state.data.productPrice}</h4>
                            <h5 className=" text-secondary text-sm line-through tracking-widest">{state.state.data.productDiscount}</h5>
                        </div>

                        <div className="my-4 max-w-12">
                            <p className="text-secondary text-sm">Color:</p>
                            <img className="border border-grey-100 my-4" src={state.state.data.productColor} alt="color" />
                        </div>

                        <div className="my-4">
                            <p className="text-secondary text-sm">Size:</p>
                            {
                                state.state.data.productSize.map((data) => {
                                    return (
                                        <div className="mr-4 inline-flex my-4"><Badge name={data}/></div>
                                    );
                                })
                            }
                            <span onClick={() => setShowModal(true)} className="text-secondary text-xs inline-flex cursor-pointer underline" style={{display: "flex"}}><TbRulerMeasure size={16} className="mx-2" />Size Guide:</span>
                        </div>

                        <hr/>
                        <div className="text-secondary tracking-normal">
                            <Accordion title="Deskripsi" content={state.state.data.productDescription} />
                            <Accordion title="Bahan" content={state.state.data.productFabric} />
                            <Accordion title="Cara Perawatan" content={state.state.data.productCare} />
                        </div>
                    </div>              
                </div>
            </div>
        </section>

        {showModal ? (
            <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-xl">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">                   
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button type="button" onClick={() => setShowModal(false)} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"> Close </button>
                            </div>
                            {/*body*/}
                            <div className="relative flex-auto">
                                <img src={state.state.data.productSizeChart} alt="sizeChart"></img>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
        ) : null}
    </div>
  );
}

export default Details;
