import Carousel from '../components/carousel';
import Categories from '../components/categories';
import CompanyList from '../components/companyList';
// import News from '../components/news';
import Products from '../components/products';
import Reviews from '../components/reviews';

function Homepage() {
  return (
    <>
        <Carousel/>
        <Categories/>
        <Products/>
        {/* <News/> */}
        <CompanyList/>
        <Reviews/>
    </>
  );
}

export default Homepage;
